import { Alert, Modal } from '@grafana/ui';

import { t } from '../../core/internationalization';

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

export function QueryLibraryExpmInfo({ isOpen, onDismiss }: Props) {
  return (
    <Modal title={t('query-library.extra-info-modal.title', 'Query library')} isOpen={isOpen} onDismiss={onDismiss}>
      <Alert
        severity="info"
        title={t('query-library.extra-info-modal.info', 'Query library is in the experimental mode. It is a place where you can save your queries and share them with your team. Once you save a query, it will be available for the whole organization to use.')}
      />
      <Alert severity="info" title={t('query-library.extra-info-modal.limit', 'Currently we are limiting the number of saved queries per organization to 1000')} />
      <Alert
        severity="warning"
        title={t('query-library.extra-info-modal.data-loss', "Although it's unlikely, some data loss may occur during the experimental phase.")}
      />
    </Modal>
  );
}

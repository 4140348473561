import { E2ESelectors } from '@grafana/e2e-selectors';

export const QueryLibraryComponents = {
  saveQueryButton: {
    button: 'data-testid save to query library button'
  },
  saveQueryModal: {
    modal: 'data-testid save to query library modal',
    cancel: 'data-testid save to query library modal cancel',
    confirm: 'data-testid save to query library modal confirm',
    description: 'data-testid save to query library modal description',
  },
  queryLibraryDrawer: {
    content: 'data-testid query library content',
    delete: 'data-testid query library delete',
    confirm: 'data-testid query library confirm',
    item: (title: string) => `data-testid query library item ${title}`,
  }
};

export const QueryLibraryPages = {};

export const selectors: {
  pages: E2ESelectors<typeof QueryLibraryPages>;
  components: E2ESelectors<typeof QueryLibraryComponents>;
} = {
  pages: QueryLibraryPages,
  components: QueryLibraryComponents,
};
